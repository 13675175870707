export default function AjaxRequest(body, response) {
  fetch("/patho/serviceClient.php", {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((Response) => Response.json()).then(response);
}

