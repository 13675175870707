import React, { Component } from 'react';
import PasswordForm from './PasswordForm';
import plusicon from './plus.svg';
import deleteicon from './delete.svg';
import undoicon from './undo.svg';
import './PathTeam.css';

function ConfigField(props) {
  return (
    <div className="form-aligned-row">
      <div className="form-aligned-left">
        <label htmlFor={props.name}>{props.caption}{!props.optional ? <span className="mandatory">*</span> : null}:</label>
      </div>
      <div className="form-aligned-right">
        <input id={props.name} type="text" placeholder={props.placeholder !== undefined ? props.placeholder : props.caption} className={props.invalid ? "invalidField" : ""} defaultValue={props.value} maxLength={props.maxLength} disabled={props.disabled} required={!props.optional} />
      </div>
    </div>
  );
}

function OptionField(props) {
  let generateInput = (l) => <input type="radio" id={props.name+"_"+l} name={props.name} defaultChecked={props.value===l} value={l} disabled={props.disabled} required={!props.optional} onClick={() => {if (props.onChange !== undefined) props.onChange(l);}} />;
  let generateLabel = (l) => <label htmlFor={props.name+"_"+l}>{props.items[l]}</label>;
  let itemCount = Object.keys(props.items).length;
  
  return (
    <div className="form-aligned-row">
      <div className="form-aligned-left">{props.caption}{!props.optional ? <span className="mandatory">*</span> : null}:</div>
      <div className="form-aligned-right radio-toolbar">
        {Object.keys(props.items).map((l) => itemCount > 2 ? <div key={l}>{generateInput(l)}{generateLabel(l)}</div> : <span key={l}>{generateInput(l)}{generateLabel(l)}</span>)}
      </div>
    </div>
  );
}

function ToggleAccordion(element) {
  element.classList.toggle("active");
  var panel = element.nextElementSibling;
  if (panel.style.maxHeight) {
    panel.style.maxHeight = null;
  } else {
    panel.style.maxHeight = panel.scrollHeight + "px";
  } 
}

function ProvincesSelect(props) {
  let provinces = ['QC', 'AB', 'BC', 'PE', 'MB', 'NB', 'NS', 'ON', 'NL', 'SK', 'NU', 'NT', 'YT'];

  return(
    <div className="form-aligned-row">
      <div className="form-aligned-left">{props.caption}{!props.optional ? <span className="mandatory">*</span> : null}:</div>
      <div className="form-aligned-right">
        <select id={props.name} defaultValue={props.value} disabled={props.disabled}>
          {provinces.map((p) => <option key={p} value={p}>{props.texts[p]}</option>)}
        </select>
      </div>
    </div>
  );
}

function ClinicConfig(props) {
  let p = "clin_"+props.clinic.id+"_";
  return(
    <div id={"clin_"+props.clinic.id} className="pure-u-1 pure-u-sm-3-4 pure-u-md-7-12">
      <button type="button" className="accordion" onClick={(e) => { e.preventDefault(); if (e.target.tagName.toLowerCase() !== 'img') { let reqFields = e.currentTarget.nextElementSibling.querySelectorAll("[required]"); let fieldsOk = true; for(var i=0;i<reqFields.length;i++){if (reqFields[i].value.trim() === '') fieldsOk = false;} if (fieldsOk) ToggleAccordion(e.currentTarget); else alert(props.texts.config_fieldmsg);}}}>{props.deletable ? <span><img src={props.deleted ? undoicon : deleteicon} className="accordionicon" alt={props.deleted ? props.texts.config_restore : props.texts.config_del} onClick={() => props.deleteHandler(props.deleted ? 'undo' : 'del', props.clinic.id)} /> </span> : null}<span className={props.deleted ? "deleted" : null}>{props.clinic.short.trim() !== '' ? props.clinic.short : props.clinic.name}</span></button>
      <div className="panel config-panel">
        <div className="form-aligned">
          <ConfigField name={p+"name"} caption={props.texts.name} placeholder={props.texts.config_clinname} maxLength="100" value={props.clinic.name} disabled={props.disabled} />
          <ConfigField name={p+"short"} caption={props.texts.config_cshort} placeholder={props.texts.config_cshortph} maxLength="20" value={props.clinic.short} disabled={props.disabled} optional />
          <ConfigField name={p+"address"} caption={props.texts.config_address} maxLength="256" value={props.clinic.address} disabled={props.disabled} />
          <ConfigField name={p+"city"} caption={props.texts.config_city} maxLength="30" value={props.clinic.city} disabled={props.disabled} />
          <ProvincesSelect name={p+"prov"} caption={props.texts.config_prov} texts={props.texts.provinces} value={props.clinic.prov} disabled={props.disabled} />
          <ConfigField name={p+"pc"} caption={props.texts.config_pc} maxLength="7" value={props.clinic.pc} disabled={props.disabled} />
          <ConfigField name={p+"tel"} caption={props.texts.config_phone} placeholder="(xxx) xxx-xxxx" maxLength="30" value={props.clinic.tel} disabled={props.disabled} />
          <ConfigField name={p+"fax"} caption={props.texts.config_fax} placeholder="(xxx) xxx-xxxx" maxLength="14" value={props.clinic.fax} disabled={props.disabled} optional />
          <ConfigField name={p+"email"} caption={props.texts.email} placeholder={props.texts.config_cemailph} maxLength="256" value={props.clinic.email} disabled={props.disabled} invalid={props.emailInvalid} optional />
        </div>
      </div>
    </div>
  );
}

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newClinics: [],
      deletedClinics: [],
      passwordEmpty: true,
      passwordValid: false,
      isConfirmed: !this.props.newAccount
    }
    this.clinicaddid = 1;
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  componentDidMount() {
    let elements = document.getElementsByClassName("accordion");
    [].forEach.call(elements, function (e) {ToggleAccordion(e);});
  }

  save() {
    let clientInfo = {
      fn: document.getElementById('d_fn').value,
      ln: document.getElementById('d_ln').value,
      sex: document.querySelector("#configform input[name='d_sex']:checked").value,
      pranum: document.getElementById('d_pranum').value,
      cell: document.getElementById('d_cell').value,
      email: document.getElementById('d_email').value,
      sp: document.getElementById('d_sp').value,
      lang: document.querySelector("#configform input[name='d_lang']:checked").value,
      moc: this.props.newAccount ? 'e' : document.querySelector("#configform input[name='d_moc']:checked").value
    };
    let clinics = this.props.clinics.filter((c) => !c.readonly).map(c => ({
      id: c.id,
      name: document.getElementById('clin_'+c.id+'_name').value,
      short: document.getElementById('clin_'+c.id+'_short').value,
      address: document.getElementById('clin_'+c.id+'_address').value,
      city: document.getElementById('clin_'+c.id+'_city').value,
      prov: document.getElementById('clin_'+c.id+'_prov').value,
      pc: document.getElementById('clin_'+c.id+'_pc').value,
      tel: document.getElementById('clin_'+c.id+'_tel').value,
      fax: document.getElementById('clin_'+c.id+'_fax').value,
      email: document.getElementById('clin_'+c.id+'_email').value,
      delete: this.state.deletedClinics.indexOf(c.id) !== -1
    }));
    let newClinics = this.state.newClinics.map(c => ({
      name: document.getElementById('clin_'+c.props.clinic.id+'_name').value,
      short: document.getElementById('clin_'+c.props.clinic.id+'_short').value,
      address: document.getElementById('clin_'+c.props.clinic.id+'_address').value,
      city: document.getElementById('clin_'+c.props.clinic.id+'_city').value,
      prov: document.getElementById('clin_'+c.props.clinic.id+'_prov').value,
      pc: document.getElementById('clin_'+c.props.clinic.id+'_pc').value,
      tel: document.getElementById('clin_'+c.props.clinic.id+'_tel').value,
      fax: document.getElementById('clin_'+c.props.clinic.id+'_fax').value,
      email: document.getElementById('clin_'+c.props.clinic.id+'_email').value
    }));
    let password = document.getElementById('password').value;

    this.props.onSubmit(clientInfo, clinics, newClinics, password);
  }

  addClinic() {
    let newid = "new_"+this.clinicaddid;
    let newClinList = this.state.newClinics.concat([<ClinicConfig clinic={{id:newid, name: this.props.texts.config_clinnew, short: ""}} texts={this.props.texts} deletable deleteHandler={(a, id) => this.deleteNewClinic(id)} />]);
    this.clinicaddid += 1;
    this.setState({newClinics: newClinList}, () => {
      ToggleAccordion(document.getElementById('clin_'+newid).getElementsByClassName("accordion")[0]);
    });
  }

  deleteClinicHandler(a, id) {
    switch (a) {
      case 'del':
        this.setState({deletedClinics: this.state.deletedClinics.concat([id])});
        break;
      case 'undo':
        var newArray = [];
        for (var i = 0; i < this.state.deletedClinics.length; i++) {
          var c_id = this.state.deletedClinics[i];
          if (c_id !== id) {
            newArray.push(this.state.deletedClinics[i]);
          }
        }

        this.setState({deletedClinics: newArray});
        break;
      default:
    }
  }

  deleteNewClinic(id) {
    var newArray = [];
    for (var i = 0; i < this.state.newClinics.length; i++) {
      var c_id = this.state.newClinics[i].props.clinic.id;
      if (c_id !== id) {
        newArray.push(this.state.newClinics[i]);
      }
    }

    this.setState({newClinics: newArray});
  }

  handlePasswordChange(isEmpty, isValid) {
    this.setState({passwordEmpty: isEmpty, passwordValid: isValid});
  }

  validateBoxClick(state) {
    this.setState({
      isConfirmed: state
    });
  }

  render() {
    return(
      <form className="pure-form" id="configform" onSubmit={(e) => { e.preventDefault(); this.save();}}>
        {this.props.legend !== undefined ? <legend>{this.props.legend}</legend> : null}
        {this.props.errors.map((e) => <div key={e} className="errorMessage">{this.props.texts.config_errors[e]}</div>)}
        <h3>{this.props.texts.config_client}</h3>
        <div className="pure-u-1 pure-u-sm-2-3 pure-u-md-7-12 form-aligned">
          <ConfigField name="d_fn" caption={this.props.texts.config_fn} maxLength="40" value={this.props.userInfo.fn} disabled={this.props.disabled} />
          <ConfigField name="d_ln" caption={this.props.texts.config_ln} maxLength="40" value={this.props.userInfo.ln} disabled={this.props.disabled} />
          <OptionField name="d_sex" caption={this.props.texts.config_s} items={{'M':this.props.texts.config_sm,'F':this.props.texts.config_sf}} value={this.props.userInfo.sex} disabled={this.props.disabled} />
          <ConfigField name="d_pranum" caption={this.props.texts.lic} placeholder="2-000000" maxLength="10" value={this.props.userInfo.pra_num} disabled={this.props.disabled} />
          <ConfigField name="d_cell" caption={this.props.texts.config_cell} placeholder="(xxx) xxx-xxxx" value={this.props.userInfo.cell} maxLength="14" disabled={this.props.disabled} optional />
          <ConfigField name="d_email" caption={this.props.texts.email} placeholder={this.props.texts.config_emailph} maxLength="256" value={this.props.email !== undefined ? this.props.email : this.props.userInfo.email} disabled={this.props.disabled} invalid={this.props.errors.indexOf('client_email') !== -1 || this.props.errors.indexOf('email_duplicate') !== -1} />
          <ConfigField name="d_sp" caption={this.props.texts.config_sp} maxLength="256" value={this.props.userInfo.sp} disabled={this.props.disabled} optional />
          <OptionField name="d_lang" caption={this.props.texts.config_lang} items={this.props.langs} value={this.props.userInfo.lang} disabled={this.props.disabled} onChange={(l) => {if (this.props.funcSetLang !== undefined) this.props.funcSetLang(l);}} />
          {!this.props.newAccount ? <OptionField name="d_moc" caption={this.props.texts.config_moc} items={{'e':this.props.texts.email,'f':this.props.texts.config_fax,'m':this.props.texts.config_mail}} value={this.props.userInfo.moc} disabled={this.props.disabled} /> : null}
        </div>
        <h3>{this.props.texts.config_clins}</h3>
        {this.props.clinics.map(c => <ClinicConfig key={c.id} clinic={c} deletable={c.deletable} deleted={this.state.deletedClinics.indexOf(c.id) !== -1} deleteHandler={(a, id) => this.deleteClinicHandler(a, c.id)} texts={this.props.texts} disabled={c.readonly || this.state.deletedClinics.indexOf(c.id) !== -1 || this.props.disabled} />)}
        {this.state.newClinics}
        <div className="addclinic">
          <button type="button" className="pure-button" onClick={() => this.addClinic()} disabled={this.props.disabled}><img src={plusicon} className="pure-img" alt="" />&nbsp;{this.props.texts.config_clinadd}</button>
        </div>
        <h3>{this.props.newAccount ? this.props.texts.passwd : this.props.texts.config_editpass}</h3>
        <PasswordForm handleValidityChange={this.handlePasswordChange} disabled={this.props.disabled} texts={this.props.texts} />
	{this.props.newAccount ?
          <p className="confirm-box">
            <label><input type="checkbox" onClick={(e) => this.validateBoxClick(e.target.checked)} />{this.props.texts.config_disc}</label>
          </p>
        : null}
        <div className="section-start">
          <button type="submit" className="pure-button pure-button-primary pure-input-rounded" disabled={this.props.disabled || (this.props.newAccount && !this.state.passwordValid) || (!this.props.newAccount && !this.state.passwordValid && !this.state.passwordEmpty) || !this.state.isConfirmed}>{this.props.newAccount ? this.props.texts.send : this.props.texts.config_save}</button>
        </div>
      </form>
    );
  }
}

export default Config;

