import React, { useState, useEffect } from 'react';
import logouticon from './logout.svg';
import AjaxRequest from './AjaxRequest';

function Privacy(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [name, setName] = useState(null);
  const [signName, setSignName] = useState("");
  const [signDate, setSignDate] = useState("");
  const [docId, setDocId] = useState(null);

  const nameIsValid = () => {
    return name !== null && name.toLowerCase() === signName.trim().toLowerCase();
  };

  useEffect(() => {
    AjaxRequest({action: 'get_privacy_info'}, (resp) => {
      if (resp.status === 'OK') {
        setName(resp.name);
        document.getElementById('privacyText').innerHTML = resp.policy;
        setSignDate(resp.signdate);
        setDocId(resp.privacyid)
        setIsLoaded(true);
      }
    });
  });

  const doSign = () => {
    AjaxRequest({action: 'sign_privacy', sign_name: signName.trim()}, (resp) => {
      if (resp.status === 'OK') {
        props.onSigned();
      }
    });
  }

  return (
    <div>
      {!props.viewMode &&
        <>
          <link rel="stylesheet" href="grids-responsive-min.css" />
          <div className="pure-menu" style={{paddingLeft: 'auto', marginRight: 0}}>
            <ul className="pure-menu-list">
              <li className="pure-menu-item"><button onClick={() => { AjaxRequest({action: 'logout'}, (resp) => { if (resp.logout === 'OK') { props.funcLoggedOut();}}); props.funcLoggedOut(); }} className="pure-menu-link link-color link-button"><img src={logouticon} className="menuicon" alt="" />{props.texts.logout}</button></li>
            </ul>
          </div>
        </>
      }
      <div className="center">
        <h1>{props.texts.priv}</h1>
        {props.viewMode &&
        <>
          <p>{props.texts.priv_signed} {signDate}</p>
          <p><button className="pure-button pure-button-primary pure-input-rounded" onClick={() => window.open("/patho/serviceClient.php?getprivacypdf="+docId)}>{props.texts.priv_down}</button></p>
          <br />
        </>
        }
      </div>
      {!props.viewMode && <p className="privacy">{props.texts.priv_read}</p> }

      <div id="privacyText" className="privacy">
      </div>
      {!props.viewMode &&
        <div class="center">
        <form className="pure-form" onSubmit={(e) => { doSign(); e.preventDefault(); }}>
          <label htmlFor="pln">{props.texts.priv_sign}</label>
          &nbsp;&nbsp;&nbsp;
          <input id="uname" type="text" value={signName} onChange={e => setSignName(e.target.value)} placeholder={props.texts.name} maxLength="100" required />
          <p><button className="pure-button pure-button-primary pure-input-rounded" disabled={!isLoaded || !nameIsValid()}>{props.texts.priv_submit}</button></p>
          <br />
          <br />
        </form>
        </div>
      }
      </div>
  );
}

export default Privacy;
