import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Popup from "reactjs-popup";
import Linkify from 'react-linkify';
import WaitScreen from './WaitScreen';
import Menu from './Menu';
import AjaxRequest from './AjaxRequest';
import Config from './Config';
import { RequestForm } from './RequestForm';
import Privacy from './Privacy';
import editicon from './pen.svg';
import downloadicon from './download.svg';
import downloadbutton from './download-button.svg';
import payicon from './pay.svg';
import readicon from './read.svg';
import isreadicon from './read-1.svg';
import isnotreadicon from './read-0.svg';
import nexticon from './next.svg';
import previcon from './prev.svg';
import firsticon from './first.svg';
import lasticon from './last.svg';
import deleteicon from './delete-light.svg';
import loading from './loading.svg';
import './PathTeam.css';
import './Client.css';
import './ResponsiveTable.css'
import 'reactjs-popup/dist/index.css';

class ClientConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
      langs: [],
      userInfo: null,
      clinics: null,
      sending: false,
      success: false,
      errors: []
    }
  }

  componentDidMount() {
    AjaxRequest({action: 'getclientconfig'}, (resp) => {
      if (resp.status === 'AUTHFAIL') {
        this.props.onLoggedOut();
      } else {
        this.setState({
          langs: resp.langs,
          userInfo: resp.userinfo,
          clinics: resp.clinics
        });
      }
    });
  }

  send(data, callBack) {
    this.setState({
      sending: true
    }, () => {
      AjaxRequest(data, callBack);
    });
  }

  save(clientInfo, clinics, newClinics, password) {
    this.send(
      {
        action: 'updateconfig',
        password: password,
        clientInfo: clientInfo,
        clinics: clinics,
        clinics_new: newClinics
      }, (resp) => {
        if (resp.status === 'OK') {
          this.setState({
            success: true,
            errors: []
          }, () => {
            this.props.funcSetLang(resp.userinfo.lang);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTimeout(() => {
              this.setState({
                sending: false,
                key: Date.now(),
                userInfo: resp.userinfo,
                clinics: resp.clinics,
                newClinics: []
              });
            }, 1500);
          });
        } else {
          this.setState({
            success: false,
            errors: resp.errors
          }, () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({sending: false});
          });
        }
    });
  }

  render() {
    if (this.state.userInfo === null) {
      return (
        <div className="loading">
          <img src={loading} className="pure-img" alt={this.props.texts.loading} />
        </div>
      );
    } else {
      return (
        <div className="configpage">
          <h2>{this.props.texts.menu.config}</h2>
          {this.state.success ? <div>{this.props.texts.config_success}</div> : null}
          <Config key={this.state.key} texts={this.props.texts} userInfo={this.state.userInfo} clinics={this.state.clinics} newClinics={this.state.newClinics} langs={this.state.langs} onSubmit={(i,c,n,p) => this.save(i,c,n,p)} disabled={this.state.sending} errors={this.state.errors} />
        </div>
      );
    }
  }
}

class CommentField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false
    }
  }

  editMode() {
    this.setState({
      isEditing: true
    }, () => {
       document.getElementById('commfield_'+this.props.id).focus(); 
    });
  }

  onChange(text) {
    this.props.onChange(text);
    this.setState({
      isEditing: false
    });
  }

  render() {
    return this.state.isEditing
           ? <textarea id={'commfield_'+this.props.id} rows="2" className="comment-field" placeholder={this.props.texts.comms} onBlur={(e) => this.onChange(e.target.value)} defaultValue={this.props.comment} />
           : <div>{this.props.comment === '' ? <em>{this.props.texts.tab_comm_none}</em> : this.props.comment} &nbsp;<button className="table-button" onClick={() => this.editMode()}><img className="tableicon" src={editicon} alt={this.props.texts.edit} /></button></div>;
  }
}

function DataCol(props) {
  switch (props.col) {
    case 'notes':
      return <td className="table-comment"><CommentField comment={props.line.notes} texts={props.texts} onChange={(text) => props.onCommentChange(text)} /></td>;
    case 'isRead':
      return <td><button className="table-button" onClick={() => props.onReadClick()}><img src={props.line.isRead === '1' ? isreadicon : isnotreadicon} className="tableicon" alt={props.line.isRead === '1' ? props.texts.tab_read : props.texts.tab_nread} /></button></td>;
    default:
      return <td>{props.line[props.col]}</td>;
  }
}

function DataLine(props) {
  return(
    <tr>
      {props.columns.map((col, index) => <DataCol key={index} col={col.key} line={props.data} texts={props.texts} onCommentChange={props.onCommentChange} onReadClick={props.onReadClick} />)}
      {props.extraAction !== undefined ? <td>{props.extraAction.available(props.data) ? <button className="table-button" onClick={() => props.extraAction.execute(props.data.id, props.extraActionCallback)}><img className="tableicon" src={props.extraAction.icon} alt={props.extraAction.text} /></button> : "-"}</td> : null}
      <td><button className="table-button" onClick={() => {window.open("/patho/serviceClient.php?get"+props.type+"="+props.data.id); props.onDownloadClick();}}><img className="tableicon" src={downloadbutton} alt={props.texts.dl} /></button></td>
    </tr>
  );
}

function ToggleAccordion(element) {
  element.classList.toggle("active");
  var panel = element.nextElementSibling;
  if (panel.style.maxHeight) {
    panel.style.maxHeight = null;
  } else {
    panel.style.maxHeight = panel.scrollHeight + "px";
  } 
}

function DataAccordion(props) {
  return(
    <div>
      <button className="accordion" onClick={(e) => ToggleAccordion(e.currentTarget)}><img src={props.data.isRead === '1' ? isreadicon : isnotreadicon} className="accordionicon" alt={props.data.isRead === '1' ? props.texts.tab_read : props.texts.tab_nread} /> {props.title.map((e) => props.data[e]).join(' - ')}</button>
      <div className="panel">
        <div className="panel-table">
          {props.columns.map((col, index) => <div key={index} className="panel-row">
            <div className="panel-left">{props.texts[col.title]}:</div>
            <div className="panel-right">{col.key === 'notes' ? <CommentField comment={props.data.notes} texts={props.texts} onChange={(text) => props.onCommentChange(text)} /> : (col.key === 'isRead' ? <button className="table-button" onClick={() => props.onReadClick()}><img src={props.data.isRead === '1' ? isreadicon : isnotreadicon} className="tableicon" alt={props.data.isRead === '1' ? props.texts.tab_read : props.texts.tab_nread} /> {props.data.isRead === '1' ? props.texts.tab_read : props.texts.tab_nread}</button> : props.data[col.key])}</div>
          </div>)}
        </div>
        <div className="tableAction">{props.extraAction !== undefined && props.extraAction.available(props.data) ? <button className="pure-button" onClick={() => props.extraAction.execute(props.data.id, props.extraActionCallback)}><img className="tableicon" src={props.extraAction.icon} alt={props.texts[props.extraAction.text]} />&nbsp;{props.texts[props.extraAction.text]}</button> : null}<button className="pure-button" onClick={() => {window.open("/patho/serviceClient.php?get"+props.type+"="+props.data.id); props.onDownloadClick();}}><img className="tableicon" src={downloadbutton} alt={props.texts.dl} />&nbsp;{props.texts.dl}</button></div>
      </div>
    </div>
  );
}

class TableSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasText: false
    }

    this.delayTimer = null;
  }

  doSearch(text, callback) {
    clearTimeout(this.delayTimer);

    this.setState({hasText: (text !== '')});

    if (text !== '') {
      this.delayTimer = setTimeout(
        () => this.props.onSearch(text)
      , 700); // Will do the ajax stuff after 1000 ms, or 1 s
    } else {
      this.props.onSearch('');
    }
  }

  clearState() {
    clearTimeout(this.delayTimer);
    this.setState({hasText: false});
    this.props.onSearch('');
  }

  render() {
    return (
      <div className={this.state.hasText ? "table-search" : "table-search table-search-empty"}>
        <input type="text" placeholder={this.props.placeholder} onKeyUp={(e) => this.doSearch(e.target.value)} />
        {this.state.hasText ? <button className="table-button" onClick={(e) => {e.currentTarget.previousSibling.value = ''; this.clearState();}}><img src={deleteicon} alt="Effacer" /></button> : null}
      </div>
    );
  }
}

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      years: [],
      pos: {
        curPage: 0,
        pageCount: 0,
        perPage: 0,
        yearFilter: null,
        search: ''
      }
    }
  }

  componentDidMount() {
    this.update(1, 10, null, '');
  }

  update(curPage, perPage, year, search) {
    AjaxRequest({action: 'getdata', type: this.props.type, curPage: curPage, perPage: perPage, year: year, search: search}, (resp) => {
      this.setState({
        data: resp.data,
        years: resp.years,
        pos: {
          curPage: curPage,
          pageCount: resp.pageCount,
          perPage: perPage,
          yearFilter: year,
          search: search
        }
      })});
  }

  setYearFilter(year) {
    this.update(1, this.state.pos.perPage, year, this.state.pos.search);
  }

  setSearch(text) {
    this.update(1, this.state.pos.perPage, this.state.pos.yearFilter, text);
  }

  setPage(p) {
    this.update(p, this.state.pos.perPage, this.state.pos.yearFilter, this.state.pos.search);
  }
  
  refresh() {
    this.update(this.state.pos.curPage, this.state.pos.perPage, this.state.pos.yearFilter, this.state.pos.search);
  }

  setRead(id, index, isRead) {
    AjaxRequest({action: 'setread', type: this.props.type, id: id, status: isRead}, (resp) => {
      if (resp.status === 'OK') {
        let newData = this.state.data;
        newData[index].isRead = isRead ? '1' : '0';
        this.setState({
          data: newData
        });
      }});
  }

  updateRead(id, index) {
    AjaxRequest({action: 'getread', type: this.props.type, id: id}, (resp) => {
      if (resp.status === 'OK') {
        let newData = this.state.data;
        newData[index].isRead = resp.isRead;
        this.setState({
          data: newData
        });
      }});
  }

  setComment(id, index, text) {
    AjaxRequest({action: 'setcomment', type: this.props.type, id: id, comment: text}, (resp) => {
      if (resp.status === 'OK') {
        let newData = this.state.data;
        newData[index].notes = text;
        this.setState({
          data: newData
        });
      }});
  }

  render() {
  if (this.state.data === null) {
     return <div className="loading"><img src={loading} className="pure-img" alt={this.props.texts.loading} /></div>
  } else if (this.state.pos.search === '' && this.state.pos.yearFilter === null && this.state.data.length === 0) {
     return this.props.notfoundtext;
  } else {
    
    return (
      <div className="data">
        <div className="table-controls">
          <div className="table-yearfilter">
            {this.props.texts.tab_year}:&nbsp;
            <select value={this.state.pos.yearFilter === null ? 'a' : this.state.pos.yearFilter} onChange={(e) => this.setYearFilter(e.target.value !== 'a' ? e.target.value : null)}>
              <option value="a">{this.props.texts.tab_yall}</option>
              {this.state.years.map((y, i) => <option key={i}>{y}</option>)}
            </select>
          </div>
          <TableSearch onSearch={(t) => this.setSearch(t)} placeholder={this.props.texts.tab_search} />
        </div>
        {this.state.data.length === 0 ? <div>{this.props.notfoundtext}</div> :
        <div>
          <div className="data-accordion">
            {this.state.data.map((data, index) => <DataAccordion columns={this.props.columns} data={data} key={data.id} type={this.props.type} texts={this.props.texts} title={this.props.accordiontitle} onReadClick={() => this.setRead(data.id, index, data.isRead === '1' ? false : true)} onDownloadClick={() => setTimeout(() => this.updateRead(data.id, index), 3000)} onCommentChange={(text) => this.setComment(data.id, index, text)} extraAction={this.props.extraAction} extraActionCallback={(id) => this.props.extraActionCallback(id, () => this.refresh())} />)}
          </div>
          <div className="data-table">
            <table className="pure-table pure-table-bordered center client-table">
              <thead>
                <tr>
                  {this.props.columns.map((col, index) => <th key={index}>{col.img !== undefined ? <img src={col.img} className="tableicon" alt={this.props.texts[col.title]} /> : this.props.texts[col.title]}</th>)}
                  {this.props.extraAction !== undefined ? <th>{this.props.texts[this.props.extraAction.text]}</th> : null}
                  <th><img src={downloadicon} className="tableicon" alt={this.props.texts.dl} /></th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.map((data, index) => <DataLine columns={this.props.columns} data={data} key={data.id} type={this.props.type} texts={this.props.texts} onReadClick={() => this.setRead(data.id, index, data.isRead === '1' ? false : true)} onDownloadClick={() => setTimeout(() => this.updateRead(data.id, index), 3000)} onCommentChange={(text) => this.setComment(data.id, index, text)} extraAction={this.props.extraAction} extraActionCallback={(id) => this.props.extraActionCallback(id, () => this.refresh())} />)}
              </tbody>
            </table>
          </div>
          <div className="table-controls">
            <div className="table-buttons">
              <button disabled={this.state.pos.curPage === 1} onClick={() => this.setPage(1)}><img src={firsticon} alt="" /></button>
              <button disabled={this.state.pos.curPage === 1} onClick={() => this.setPage(this.state.pos.curPage-1)}><img src={previcon} alt="" /></button>
              <button disabled={this.state.pos.curPage === this.state.pos.pageCount} onClick={() => this.setPage(this.state.pos.curPage+1)}><img src={nexticon} alt="" /></button>
              <button disabled={this.state.pos.curPage === this.state.pos.pageCount} onClick={() => this.setPage(this.state.pos.pageCount)}><img src={lasticon} alt="" /></button>
            </div>
            <div className="table-page">
              Page {this.state.pos.curPage} {this.props.texts.tab_of} {this.state.pos.pageCount}
            </div>
          </div>
        </div>
        }
      </div>
    );
    }
  }
}

var reportTable = [
  {key: 'id', title: 'tab_num'},
  {key: 'datePrel', title: 'tab_prel'},
  {key: 'dateRec', title: 'tab_rec'},
  {key: 'notes', title: 'comms'},
  {key: 'isRead', title: 'tab_read', img: readicon},
  {key: 'path_name', title: 'tab_path'},
  {key: 'clinic', title: 'clinic'},
  {key: 'patient_name', title: 'patient'},
  {key: 'dateRep', title: 'tab_daterep'}
];

var billTable = [
  {key: 'num', title: 'tab_num'},
  {key: 'billDate', title: 'date'},
  {key: 'billName', title: 'name'},
  {key: 'nr', title: 'tab_nbcase'},
  {key: 'notes', title: 'comms'},
  {key: 'isRead', title: 'tab_read', img: readicon},
  {key: 'total', title: 'tab_total'},
  {key: 'due', title: 'tab_due'}
];

function openLightboxPayment(token, approvedCallback) {
  var paymentFields = {
    ssl_txn_auth_token: token
  };
  var callback = {
    onError: function (error) {
      alert("error: " + error);
    },
    onCancelled: function () {
    },
    onDeclined: function (response) {
      //alert("declined: " + JSON.stringify(response, null, '\t'));
    },
    onApproval: function (response) {
      if (response['ssl_result_message']==='APPROVAL') {
        AjaxRequest({action: 'validatepayment', data: response}, (resp) => {
          if (resp.status === 'OK') {
            approvedCallback();
          }
        });
      }
    }
  };
  window.PayWithConverge.open(paymentFields, callback);
  return false;
}

var billTablePayAction = {
  text: "tab_pay",
  icon: payicon,
  available: (l) => (l.isDue),
  execute: (id, cb) => AjaxRequest({action: 'getpaymenttoken', bill: id}, (resp) => {
    if (resp.status === 'OK') {
      openLightboxPayment(resp.token, () => cb(id));
    } else {
      alert('ERREUR: impossible d\'effectuer le paiement\nERROR: can\'t process payment');
    }
  })
};

function ClinicRadio(props) {
  return (
    <table className="ordertable">
      {props.noChoiceValue !== undefined ? <tr><td><input type="radio" name="clinic" id="clin_none" value={props.noChoiceValue} defaultChecked={true} /></td><td><label htmlFor="clin_none">{props.noChoiceText}</label></td></tr> : null}
      {props.clinics.map((c, i) => <tr key={i}><td><input type="radio" name="clinic" id={"clin_"+c.id} value={c.id} defaultChecked={(props.noChoiceValue === undefined && props.value === undefined && i===0) || props.value === c.id} /></td><td><label htmlFor={"clin_"+c.id}>{c.name}<br />{c.address}<br />{c.city}, {c.prov}  {c.cp}<br />{c.tel}</label></td></tr>)}
    </table>
  );
}

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      clinics: null,
      isConfirmed: false,
      sending: false,
      isComplete: false
    }
  }

  componentDidMount() {
    AjaxRequest({action: 'getclientconfig'}, (resp) => {
      if (resp.status === 'AUTHFAIL') {
        this.props.onLoggedOut();
      } else {
        this.setState({
          userInfo: resp.userinfo,
          clinics: resp.clinics
        });
      }
    });
  }

  validateBoxClick(state) {
    this.setState({
      isConfirmed: state
    });
  }

  submit() {
    this.setState({
      sending: true
    }, () => {
      AjaxRequest({
        action: 'order',
        clinic: document.querySelector("#orderform input[name='clinic']:checked").value,
        bottlenum: document.getElementById('bottlenum').value,
        comment: document.getElementById('comment').value
      }, (resp) => {
        if (resp.status === 'AUTHFAIL') {
          this.props.onLoggedOut();
        } else if (resp.status === 'OK') {
          this.setState({
             isComplete: true
          });
        }
      });
    });
  }

  render() {
    if (this.state.userInfo === null) {
      return (<div className="loading"><img src={loading} className="pure-img" alt={this.props.texts.loading} /></div>);
    } else if (this.state.isComplete) {
      return (
        <div className="center configpage">
          <h1>{this.props.texts.ord}</h1>
          <p>{this.props.texts.ord_sent}</p>
        </div>
      );
    }

    return (
      <div className="center configpage">
        <h1>{this.props.texts.ord}</h1>
        <p>{this.props.texts.ord_intro}</p>
        <h3>{this.props.texts.ord_id}</h3>
	<table className="ordertable">
          <tr><td>{this.state.userInfo.sex === 'F' ? this.props.texts.ord_dname_f : this.props.texts.ord_dname_m}:</td><td>{this.state.userInfo.fn} {this.state.userInfo.ln}</td></tr>
          <tr><td>{this.props.texts.lic}:</td><td>{this.state.userInfo.pra_num}</td></tr>
          <tr><td>{this.props.texts.ord_email}:</td><td>{this.state.userInfo.email}</td></tr>
        </table>
        <form id="orderform" className="pure-form pure-form-aligned" onSubmit={(e) => { e.preventDefault(); this.submit();}}>
        <h3>{this.props.texts.ord_shipa}</h3>
        <p>{this.props.texts.ord_sel}:</p>
        <ClinicRadio clinics={this.state.clinics} />
        <h3>{this.props.texts.ord_opt}</h3>
        <div className="form-aligned">
          <div className="form-aligned-row">
            <div className="form-aligned-left">
              <label htmlFor="bottlenum">{this.props.texts.ord_bot}:</label>
            </div>
            <div className="form-aligned-right">
              <input type="text" id="bottlenum" maxLength="20" size="10" />
            </div>
          </div>
          <div className="form-aligned-row">
            <div className="form-aligned-left">
              <label htmlFor="comment">{this.props.texts.comms}:</label>
            </div>
            <div className="form-aligned-right">
              <textarea rows="3" id="comment" className="order-comment" />
            </div>
          </div>
        </div>
        <p className="confirm-box">
          <label><input type="checkbox" onClick={(e) => this.validateBoxClick(e.target.checked)} />{this.props.texts.ord_conf}</label>
        </p>
        <div>
          <button type="submit" className="pure-button pure-button-primary pure-input-rounded" disabled={!this.state.isConfirmed || this.state.sending}>{this.props.texts.send}</button>
        </div>
        </form>
        <p className="footnote">*{this.props.texts.ord_discl}</p>
      </div>
    );
  }
}

class Request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      clinics: null,
      selected_clinic: null,
      submitting: false,
      reqId: null
    }
  }

  componentDidMount() {
    AjaxRequest({action: 'getclientconfig'}, (resp) => {
      if (resp.status === 'AUTHFAIL') {
        this.props.onLoggedOut();
      } else {
        this.setState({
          langs: resp.langs,
          userInfo: resp.userinfo,
          clinics: resp.clinics
        });
      }
    });
  }

  sendRequest(data) {
    this.setState({
      submitting: true
    }, () => AjaxRequest({action: 'newrequest', ...data}, (resp) => {
      if (resp.status === 'AUTHFAIL') {
        this.props.onLoggedOut();
      } else if (resp.status === 'OK') {
        this.setState({
          reqId: resp.reqid
        });
      }
    }));
  }

  render() {
    return (
      this.state.userInfo === null ? <div className="loading"><img src={loading} className="pure-img" alt={this.props.texts.loading} /></div> :
      <div className="center configpage">
        <h1>{this.props.texts.req}</h1>
        {this.state.reqId === null ?
          <RequestForm texts={this.props.texts} lang={this.state.userInfo.lang} langs={this.state.langs} clinics={this.state.clinics} disabled={this.state.submitting} onSubmit={d => this.sendRequest(d)} />
        : <div>{this.props.texts.req_success} &nbsp;<button className="pure-button pure-button-primary pure-input-rounded" onClick={() => {window.open("/patho/serviceClient.php?getreq="+this.state.reqId);}}>{this.props.texts.req_pdf}</button></div>}
      </div>
    );
  }
}

function NewsAccordion(props) {
  return(
    <div className="news">
      <button className="accordion" onClick={(e) => {ToggleAccordion(e.currentTarget); if (!props.isRead) props.onRead();}}><img src={props.isRead ? isreadicon : isnotreadicon} className="accordionicon" alt={props.isRead ? "Lu" : "Non-lu"} /> {props.date} - {props.title}</button>
      <div className="panel">
        <div className="newstext">
          <Linkify properties={{target: '_blank'}}>{props.text}</Linkify>
        </div>
      </div>
    </div>
  );
}

class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      clientName: '',
      contacts: [],
      news: [],
      modalOpen: false
    }
    
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    AjaxRequest({action: 'getinfo'}, (resp) => {
      this.setState({
        isLoaded: true,
        clientName: resp.client.fn + ' ' + resp.client.ln,
        contacts: resp.pathList,
        news: resp.news
      })});
  }

  setNewsRead(id, index) {
    AjaxRequest({action: 'setnewsread', id: id}, (resp) => {
      if (resp.status === 'OK') {
        let news = this.state.news;
        news[index].isRead = '1';
        this.setState({
          news: news
        })
      }
    });
  }
  
  setBillPaid(id, cb) {
    AjaxRequest({action: 'setbillpaid', id: id}, (resp) => {
      if (resp.status === 'OK') {
        cb();
      }
    });
  }

  logout() {
    AjaxRequest({
        action: 'logout'
      }, (resp) => {
      if (resp.logout === 'OK') {
        this.props.funcLoggedOut();
      }});
  }
  
  openModal() {
    this.setState({ modalOpen: true });
  }
  
  closeModal() {
    this.setState({ modalOpen: false });
  }

  render() {
    return this.state.isLoaded ? (
      <Router>
        <Menu funcLoggedOut={() => this.logout()} titles={this.props.texts.menu} />
        <Switch>
            <Route exact path="/">
              <div className="center">
                {this.props.texts.welcome} {this.state.clientName}!<br /><br />
                <div>{this.props.texts.question} <Popup trigger={<button className="contact-link">{this.props.texts.contactus}</button>} position="bottom center"><div>{this.state.contacts.map((p, i) => <div key={i}><a href={"mailto:"+p.fn+' '+p.ln+' <'+p.email+'>'}>{p.sex === 'F' ? this.props.texts.dr_f : this.props.texts.dr_m} {p.fn} {p.ln}</a></div>)}</div></Popup>.</div>
                {this.state.news.length > 0 ?
                  <div>
                    <h2>{this.props.texts.messages}</h2>
                    <div>{this.state.news.map((n, i) => <NewsAccordion key={n.id} isRead={n.isRead === '1'} date={n.date} title={n.title} text={n.text} onRead={() => this.setNewsRead(n.id, i)} />)}</div>
                  </div>
                : null }
                <h2>{this.props.texts.pathrep}</h2>
                <DataTable type="report" columns={reportTable} texts={this.props.texts} notfoundtext={this.props.texts.pathrep_nf} accordiontitle={['id','patient_name']} />
                <h2>{this.props.texts.inv}</h2>
                <DataTable type="bill" columns={billTable} texts={this.props.texts} notfoundtext={this.props.texts.inv_nf} accordiontitle={['num','billDate']} extraAction={billTablePayAction} extraActionCallback={(id, cb) => { this.openModal(); this.setBillPaid(id, cb);}} />
              </div>
            </Route>
            <Route path="/config">
              <div className="center"><ClientConfig texts={this.props.texts} funcSetLang={this.props.funcSetLang} onLoggedOut={() => { this.props.funcLoggedOut();}} /></div>
            </Route>
            <Route path="/order">
              <OrderForm texts={this.props.texts} onLoggedOut={() => { this.props.funcLoggedOut();}} />
            </Route>
            <Route path="/req">
              <Request texts={this.props.texts} onLoggedOut={() => { this.props.funcLoggedOut();}} />
            </Route>
            <Route path="/privacy">
              <Privacy texts={this.props.texts} viewMode={true} />)
            </Route>
            <Route path="/logout">
              <WaitScreen text={this.props.texts.logout} />
            </Route>
        </Switch>
        <Popup open={this.state.modalOpen} closeOnDocumentClick onClose={() => this.closeModal()}>
          <div className="modal">
            {this.props.texts.paysuccess}<br />
            <button className="pure-button pure-button-primary pure-input-rounded" onClick={() => this.closeModal()}>
              {this.props.texts.close}
            </button>
          </div>
        </Popup>
      </Router>
    )
    : <WaitScreen text={this.props.texts.clientloading} />;
  }
}

export default Client;
