import React from 'react';
import { createRoot } from 'react-dom/client';
import PathTeam from './PathTeam';
import * as serviceWorker from './serviceWorker';
import "isomorphic-fetch"

require('es6-promise').polyfill();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<PathTeam />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
