import React, { Component, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import AjaxRequest from './AjaxRequest';
import PasswordForm from './PasswordForm';
import Config from './Config';
import logo from './logo.svg';
import logo_en from './logo-en.svg';
import loading from './loading.svg';
import { RequestFormGuest } from './RequestForm'
import './PathTeam.css';
import './Login.css';

class ClientHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggingIn: false,
      loginError: null
    }
  }

  DoLogin() {
    this.setState({isLoggingIn: true}, 
      () => fetch("/patho/serviceClient.php", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'login',
          email: document.getElementById('logemail').value,
          password: document.getElementById('logpassword').value
        }),
      }).then((Response) => Response.json()).then((resp) => {
        if (resp.login === 'OK' || resp.login === 'PRIV') {
          this.props.funcLoggedIn(resp.lang, resp.login === 'OK');
        } else {
          this.setState({isLoggingIn: false, loginError: resp.login});
        }
      })
    );
  }
  
  render() {
    return (
      <div>
        <form className="login-form" onSubmit={(e) => { e.preventDefault(); this.DoLogin()}}>
          <div className="pure-form">
            <legend>{this.props.texts.clientaccess}</legend>
          </div>
          {this.state.loginError !== null ? <div className="errorMessage login-text">{this.state.loginError === 'LOCK' ? this.props.texts.client_errlock : this.props.texts.client_errlog}</div> : ''}
          <div>
            <div className="pure-u-1 pure-u-sm-1-3 login-text">{this.props.texts.login}</div>
            <div className="pure-u-1 pure-u-sm-1-3 login-text">
                <input type="email" id="logemail" className="custom-input" placeholder={this.props.texts.email} disabled={this.state.isLoggingIn} tabIndex="1" required />
            </div>
            <div className="pure-u-1 pure-u-sm-1-3 login-text"><Link to="/lostpass" tabIndex="4">{this.props.texts.lostpasswd}</Link></div>
          </div>
          <div>
            <div className="pure-u-sm-1-3"></div>
            <div className="pure-u-1 pure-u-sm-1-3 login-text">
                <input type="password" id="logpassword" className="custom-input" placeholder={this.props.texts.passwd} disabled={this.state.isLoggingIn} tabIndex="2" required />
            </div>
            <div className="pure-u-sm-1-3"></div>
          </div>

          <p className="pure-form">
            <button className="pure-button pure-button-primary pure-input-rounded" disabled={this.state.isLoggingIn} tabIndex="3">{this.props.texts.login_btn}</button>
          </p>
        </form>
        <div className="Login-section-start">
          <div className="pure-u-1 pure-u-sm-1-3 login-text">{this.props.texts.newuser}</div>
          <form className="pure-u-1 pure-u-sm-1-3 login-text pure-form">
            <Link to="/register"><button className="pure-button pure-input-rounded" tabIndex="5">{this.props.texts.register_btn} &gt;</button></Link>
          </form>
          <div className="pure-u-1 pure-u-sm-1-3 login-text"></div>
        </div>
        <div className="Login-section-start">
          <div className="pure-u-1 pure-u-sm-1-3 login-text">{this.props.texts.newclient}</div>
          <form className="pure-u-1 pure-u-sm-1-3 login-text pure-form">
              <Link to="/guestreq"><button className="pure-button pure-input-rounded" tabIndex="5">{this.props.texts.request_btn} &gt;</button></Link>
          </form>
          <div className="pure-u-1 pure-u-sm-1-3 login-text"></div>
        </div>
      </div>
    );
  }
}

function EmailField(props) {
  return (
    <div className="fieldsetpad">
      <div className="pure-u-1 pure-u-sm-5-12">
        <fieldset>
          <input className={props.isInvalid ? "custom-input invalidField" : "custom-input"} style={{margin: 'auto', display: 'inline-block'}} onChange={props.onChange} type="email" id={props.id} placeholder={props.placeholder} disabled={props.disabled} />
        </fieldset>
      </div>
    </div>
  );
}

function ReturnButton(props) {
  return (
    <div className="backbutton">
      <Link to="/"><button className="pure-button pure-button-primary pure-input-rounded button-secondary">&lt; {props.text}</button></Link>
    </div>
  );
}


class Identify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "start",
      emailEmpty: true
    }
  }

  emailChange() {
      let empty = (document.getElementById(this.props.actionEmail).value.trim() === '');
      this.setState({
        emailEmpty: empty
      });
  }

  validateEmail() {
    AjaxRequest({action: this.props.actionEmail, email: document.getElementById(this.props.actionEmail).value.trim()}, (resp) => {
      this.setState({
        status: resp.status
      })});
  }

  validateInfo() {
    AjaxRequest({action: this.props.actionIdentify, email: document.getElementById(this.props.actionEmail).value.trim(),
                      clientln: document.getElementById('dln').value.trim(), patientln: document.getElementById('pln').value.trim(),
                      reportid: document.getElementById('repnum').value}, (resp) => {
      this.setState({
        status: resp.status
      })});
  }

  render() {
    return (
    <div>
      <div>
        <form className="pure-form pure-form-stacked login-form" onSubmit={(e) => { e.preventDefault(); this.validateEmail()}}>
        <legend>{this.props.legend}</legend>
        <p>{this.props.texts.emailinput}</p>
        <EmailField id={this.props.actionEmail} placeholder={this.props.texts.email} isInvalid={this.state.status==="emailinvalid"} onChange={() => this.emailChange()} disabled={!(this.state.status==="start"||this.state.status==="emailinvalid")} />

        <button className="pure-button pure-button-primary pure-input-rounded" disabled={!(this.state.status==="start"||this.state.status==="emailinvalid") || this.state.emailEmpty}>{this.props.texts.continue}</button>
        </form>
      </div>
      {this.props.warning !== undefined ?
        <div className={this.state.status === "start" ? "Login-section-start" : "hidden"}>
          {this.props.warning}
        </div>
      : null}
      <div className={this.state.status === "emailinvalid" ? "Login-section-start errorMessage" : "hidden"}>
        {this.props.texts.invalidemail}
      </div>
      <div className={this.state.status === "emailexists" ? "Login-section-start" : "hidden"}>
        {this.props.texts.alreadyexists[0]}<br /><Link to="/lostpass">{this.props.texts.alreadyexists[1]}</Link> {this.props.texts.alreadyexists[2]}
      </div>
      <div className={this.state.status === "identify" || this.state.status === "identifyfail" || this.state.status === "identifysuccess" ? "Login-section-start" : "hidden"}>
        <p>{this.props.texts.identify}</p>
        <form className="pure-form pure-form-aligned registerauth" onSubmit={(e) => { e.preventDefault(); this.validateInfo()}}>
          <fieldset>
            <div className="pure-control-group">
              <label htmlFor="dln">{this.props.texts.dentistln}:</label>
              <input id="dln" type="text" placeholder={this.props.texts.config_ln} maxLength="40" disabled={this.state.status!=="identify"} required />
            </div>
            <div className="pure-control-group">
              <label htmlFor="repnum">{this.props.texts.repnum}:</label>
              <input id="repnum" type="text" placeholder="0000000" maxLength="7" pattern="[0-9]{7}" inputMode="numeric" disabled={this.state.status!=="identify"} required />
            </div>
            <div className="pure-control-group">
              <label htmlFor="pln">{this.props.texts.patientln}:</label>
              <input id="pln" type="text" placeholder={this.props.texts.config_ln} maxLength="40" disabled={this.state.status!=="identify"} required />
            </div>
          </fieldset>
        <button className="pure-button pure-button-primary pure-input-rounded" disabled={this.state.status!=="identify"}>{this.props.texts.send}</button>
        </form>
      </div>
      <div className={this.state.status === "identifyfail" ? "Login-section-start" : "hidden"}>
        <p>{this.props.notfoundtext}</p>
        <form className="pure-form" onSubmit={(e) => { e.preventDefault(); this.setState({status: "identify"});}}>
          <button className="pure-button pure-button-primary pure-input-rounded">{this.props.texts.retry}</button>
        </form>
      </div>
      <div className={this.state.status === "identifysuccess" ? "Login-section-start" : "hidden"}>
        <p>{this.props.texts.identsuccess} {this.props.successAction}. {this.props.texts.linkvalid}</p>
      </div>
      <div className={this.state.status === "exists" ? "Login-section-start" : "hidden"}>
        <p>{this.props.texts.userexists}</p>
      </div>
      <ReturnButton text={this.props.texts.return} />
    </div>
    );
  }
}

function Register(props) {
  return (
    <Identify actionEmail="registeremail" actionIdentify="identifyuser" texts={props.texts} legend={props.texts.register} warning={<div>{props.texts.regwarning[0]} <a href={"http://www.pathologiebuccale.ca/order.php?lang="+props.lang+"&province=quebec"}>{props.texts.regwarning[1]}</a> {props.texts.regwarning[2]}</div>} successAction={props.texts.register_saction} notfoundtext={props.texts.repnotfound} />
  );
}

function LostPass(props) {
  return (
    <Identify actionEmail="lostpassemail" actionIdentify="lostpass" texts={props.texts} legend={props.texts.newpasswd} successAction={props.texts.passwd_saction} notfoundtext={props.texts.notfoundpasswd} />
  );
}

class Validate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      email: null,
      langs: [],
      sending: false,
      passwordEmpty: true,
      passwordValid: false,
      success: false,
      errors: []
    }
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  componentDidMount() {
    AjaxRequest({action: 'validate', token: this.props.code}, (resp) => {
      this.setState({
        type: resp.type,
        email: resp.type === 'register' ? resp.email : null,
        langs: resp.type === 'register' ? resp.langs : null,
        userInfo: resp.type === 'register' ? resp.userinfo : null,
        clinics: resp.type === 'register' ? resp.clinics : null
      });
      if (resp.lang !== undefined) this.props.funcSetLang(resp.lang);      
    });
  }

  handlePasswordChange(isEmpty, isValid) {
    this.setState({passwordEmpty: isEmpty, passwordValid: isValid});
  }

  send(data, callBack) {
    this.setState({
      sending: true
    }, () => {
      AjaxRequest(data, callBack);
    });
  }

  register(clientInfo, clinics, newClinics, password) {
    this.send(
      {
        action: 'register',
        token: this.props.code,
        password: password,
        clientInfo: clientInfo,
        clinics: clinics,
        clinics_new: newClinics
      }, (resp) => {
        if (resp.status === 'OK') {
          this.setState({
            success: true,
            errors: []
          });
        } else {
          this.setState({
            success: false,
            sending: false,
            errors: resp.errors
          });
        }
    });
  }

  savePassword() {
    let pass = document.getElementById('password').value;

    this.send({action: 'savepassword', token: this.props.code, password: pass}, (resp) => {
      if (resp.status === 'OK') {
        this.setState({
          success: true
        });
      }
    });
  }

  render() {
    if (this.state.type === null) {
      return (
        <div className="loading">
          <img src={loading} className="pure-img" alt={this.props.texts.loading} />
        </div>
      );
    } else if (this.state.type === "invalid") {
      return (
        <div className="Login-section-start">
           {this.props.texts.valerror}<br />
           {this.props.texts.valerror_exp}
           <ReturnButton text={this.props.texts.return} />
        </div>
      );
    } else if (this.state.type === "password") {
      return (
        <div>
          <form className="pure-form" onSubmit={(e) => { e.preventDefault(); this.savePassword();}}>
            <legend>{this.props.texts.newpasswd}</legend>
            <div className="Login-section-start">
              <PasswordForm handleValidityChange={this.handlePasswordChange} texts={this.props.texts} disabled={this.state.sending} />
              <button className="pure-button pure-button-primary pure-input-rounded" disabled={this.state.sending || !this.state.passwordValid}>{this.props.texts.send}</button>
            </div>
            {this.state.success ? <div className="Login-section-start">{this.props.texts.passwd_success}<ReturnButton text={this.props.texts.return} /></div> : null}
          </form>
        </div>
      );
    } else if (this.state.type === "register") {
      return (
        <div>
          {this.state.success ?
            <form className="pure-form">
              <legend>{this.props.texts.register}</legend>
              <div className="Login-section-start">
                {this.props.texts.register_success}<ReturnButton text={this.props.texts.return} />
              </div>
            </form>
           : <Config legend={this.props.texts.register} texts={this.props.texts} funcSetLang={this.props.funcSetLang} userInfo={this.state.userInfo} email={this.state.email} clinics={this.state.clinics} newClinics={this.state.newClinics} langs={this.state.langs} onSubmit={(i,c,n,p) => this.register(i,c,n,p)} disabled={this.state.sending} errors={this.state.errors} newAccount />
          }
        </div>
      );
    } else {
      return null;
    }
  }
}

function NoMatch(props) {
  return (
    <div className="Login-section-start">
      <strong>{props.texts.error404[0]}:</strong> {props.texts.error404[1]}
      <ReturnButton text={props.texts.return} />
    </div>
  );
}

function RequestForm(props) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestId, setRequestId] = useState(null);

  useEffect(() => {
    AjaxRequest({action: 'checkguestauth'}, (resp) => {
      if (resp.auth === 'Y') {
        setRequestId(resp.reqid);
        setIsSubmitted(true);
      }
    });
  });

  function sendRequest(data) {
    setIsSubmitted(true);

    AjaxRequest({action: 'newguestrequest', ...data}, (resp) => {
      if (resp.status === 'FAIL') {
        alert('Submit error');
      } else if (resp.status === 'OK') {
        setRequestId(resp.reqid);
      }
    });
  }

  function logout() {
    AjaxRequest({action: 'logout'}, (resp) => {
      if (resp.logout === 'OK') {
        setRequestId(null);
        setIsSubmitted(false);
      }});
  }

  return (
    <>
      {requestId === null ? <RequestFormGuest lang={props.lang} texts={props.texts} disabled={isSubmitted} onSubmit={d =>sendRequest(d)} />
                          : <div>
                              <p>{props.texts.req_success} &nbsp;<button className="pure-button pure-button-primary pure-input-rounded" onClick={() => {window.open("/patho/serviceClient.php?getreq="+requestId);}}>{props.texts.req_pdf}</button></p>
                              <p><Link to="/"><button className="pure-button pure-button-primary pure-input-rounded button-secondary" onClick={() => {logout();}}>{props.texts.menu.logout}</button></Link></p>
                            </div>}
      <ReturnButton text={props.texts.return} />
    </>
  );
}


export default function Login(props) {
  var home = <ClientHome funcLoggedIn={props.funcLoggedIn} texts={props.langinfo[props.lang]} />;

  return (
    <Router>
      <div className="center Login-page Login-width">
        <div className="logo-header">
          <div className="logo-div"><a href={"http://www.pathologiebuccale.ca/?lang="+props.lang+"&province=quebec"}><img src={props.lang === 'en' ? logo_en : logo} className="pure-img" alt="Logo PathTeam" /></a></div>
          <div className="lang-switch">{props.lang === 'en' ? <button onClick={() => props.funcSetLang('fr')}>FR</button> : <button onClick={() => props.funcSetLang('en')}>EN</button>}</div>
        </div>

        <Switch>
          <Route path="/lostpass">
            <LostPass texts={props.langinfo[props.lang]} />
          </Route>
          <Route path="/register">
            <Register lang={props.lang} texts={props.langinfo[props.lang]} />
          </Route>
          <Route path="/guestreq">
            <RequestForm lang={props.lang} texts={props.langinfo[props.lang]} />
          </Route>
          <Route path="/validate/:code" render={(p) => <Validate code={p.match.params.code} funcSetLang={props.funcSetLang} texts={props.langinfo[props.lang]} />} />
          <Route path="/config">
            <Redirect to="/" />
          </Route>
          <Route path="/order">
            <Redirect to="/" />
          </Route>
          <Route path="/req">
            <Redirect to="/" />
          </Route>
          <Route path="/logout">
            <Redirect to="/" />
          </Route>
          <Route path="/en/:redirect" render={(p) => {if (props.lang !== 'en') { props.funcSetLang('en'); } p.history.replace('/'+p.match.params.redirect);}} />
          <Route path="/fr/:redirect" render={(p) => {if (props.lang !== 'fr') { props.funcSetLang('fr'); } p.history.replace('/'+p.match.params.redirect);}} />
          <Route path="/en" render={(p) => {if (props.lang !== 'en') { props.funcSetLang('en'); } p.history.push('/'); return home;}} />
          <Route path="/fr" render={(p) => {if (props.lang !== 'fr') { props.funcSetLang('fr'); } p.history.push('/'); return home;}} />
          <Route exact path="/">
            {home}
          </Route>
          <Route path="*">
            <NoMatch texts={props.langinfo[props.lang]} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

