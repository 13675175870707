import React, { useState, useRef } from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import ReCAPTCHA from "react-google-recaptcha"
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr-CA';
registerLocale('fr', fr)

function SimpleField(props) {
  return (
    <div className="form-aligned-row">
      <div className="form-aligned-left">
        <label htmlFor={props.name}>{props.caption}{!props.optional ? <span className="mandatory">*</span> : null}:</label>
      </div>
      <div className="form-aligned-right">
        <input id={props.name} type="text" placeholder={props.placeholder !== undefined ? props.placeholder : props.caption} className={props.invalid ? "invalidField" : ""} value={props.value} onChange={e => props.onChange(e.target.value)} maxLength={props.maxLength} disabled={props.disabled} required={!props.optional} />
      </div>
    </div>
  );
}

function DateField(props) {
  return (
    <div className="form-aligned-row">
      <div className="form-aligned-left">
        <label htmlFor={props.name}>{props.caption}{!props.optional ? <span className="mandatory">*</span> : null}:</label>
      </div>
      <div className="form-aligned-right">
        <DatePicker id={props.name}
                    placeholderText={props.placeholder !== undefined ? props.placeholder : props.caption}
                    dateFormat="yyyy-MM-dd"
                    selected={props.value !== "" ? new Date(props.value) : null}
                    locale={props.lang}
                    onChange={date => props.onChange(date.toISOString().slice(0, 10))}
                    maxDate={new Date()}
                    showMonthDropdown={props.dropdown}
                    showYearDropdown={props.dropdown}
                    dropdownMode="select"
                    disabled={props.disabled}
                    required={!props.optional} />
      </div>
    </div>
  );
}

function LongField(props) {
  return(
    <div className="form-aligned-row">
      <div className="form-aligned-left">
        <label htmlFor={props.name}>{props.caption}{!props.optional ? <span className="mandatory">*</span> : null}:</label>
      </div>
      <div className="form-aligned-right">
        <textarea rows="4" cols="50" id={props.name} className="request-textfield" disabled={props.disabled} required={!props.optional} onInput={e => props.onChange(e.target.value)} value={props.value} />
      </div>
    </div>
  );
}

function OptionField(props) {
  let generateInput = (l) => <input type="radio" id={props.name+"_"+l} name={props.name} checked={props.value===l} value={l} disabled={props.disabled} required={!props.optional} onChange={() => {if (props.onChange !== undefined) props.onChange(l);}} />;
  let generateLabel = (l) => <label htmlFor={props.name+"_"+l}>{props.items[l]}</label>;
  
  return (
    <div className="form-aligned-row">
      <div className="form-aligned-left">{props.caption}{!props.optional ? <span className="mandatory">*</span> : null}:</div>
      <div className="form-aligned-right radio-toolbar">
        {Object.keys(props.items).map((l) => <span key={l}>{generateInput(l)}{generateLabel(l)}</span>)}
      </div>
    </div>
  );
}

function ClinicRadio(props) {
  return (
    <table className="ordertable">
      <tbody>
        {props.noChoiceValue !== undefined && <tr><td><input type="radio" name="clinic" id="clin_none" value={props.noChoiceValue} checked={props.value === props.noChoiceValue} onChange={() => props.onChange(props.noChoiceValue)} /></td><td><label htmlFor="clin_none">{props.noChoiceText}</label></td></tr>}
        {props.clinics.map((c, i) => <tr key={i}><td><input type="radio" name="clinic" id={"clin_"+c.id} value={c.id} checked={props.value === c.id} onChange={() => props.onChange(c.id)} /></td><td><label htmlFor={"clin_"+c.id}>{c.name}<br />{c.address}<br />{c.city}, {c.prov}  {c.cp}<br />{c.tel}</label></td></tr>)}
      </tbody>
    </table>
  );
}

function ProvincesSelect(props) {
  let provinces = ['QC', 'AB', 'BC', 'PE', 'MB', 'NB', 'NS', 'ON', 'NL', 'SK', 'NU', 'NT', 'YT'];

  return(
    <div className="form-aligned-row">
      <div className="form-aligned-left">{props.caption}{!props.optional ? <span className="mandatory">*</span> : null}:</div>
      <div className="form-aligned-right">
        <select id={props.name} value={props.value} disabled={props.disabled} onChange={(e) => props.onChange(e.target.value)}>
          {provinces.map((p) => <option key={p} value={p}>{props.texts[p]}</option>)}
        </select>
      </div>
    </div>
  );
}

function RequestForm(props) {
  let biop_types = ['bi', 'be', 'ba', 'fr', 'ifd'];
  const [step, setStep] = useState(0);
  const [selectedClinic, setSelectedClinic] = useState("e");
  const [prelDate, setPrelDate] = useState(new Date().toISOString().slice(0, 10));
  const [lang, setLang] = useState(props.lang);
  const [patientFirstName, setPatientFirstName] = useState("");
  const [patientLastName, setPatientLastName] = useState("");
  const [patientSex, setPatientSex] = useState("");
  const [patientBirthDate, setPatientBirthDate] = useState("");
  const [biopsyType, setBiopsyType] = useState("");
  const [radio, setRadio] = useState("");
  const [radioDetails, setRadioDetails] = useState("");
  const [site, setSite] = useState("");
  const [rensClin, setRensClin] = useState("");
  const [diagnostics, setDiagnostics] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);

  function isValid() {
    return patientFirstName.trim() !== '' && patientLastName.trim() !== '' && patientSex !== '' && patientBirthDate.length === 10 && biopsyType !== '' && site.trim().length > 1;
  }

  return (step < 1 ?
    <form id="reqform" className="pure-form pure-form-aligned" onSubmit={(e) => { e.preventDefault(); setStep(1);}}>
    <p>{props.texts.req_sel}:</p>
    <ClinicRadio clinics={props.clinics} noChoiceText={props.texts.req_empty} noChoiceValue="e" value={selectedClinic} onChange={setSelectedClinic} />
    <p>
      {<button type="button" className="pure-button pure-button-primary pure-input-rounded" onClick={() => window.open("/patho/serviceClient.php?getemptyreq="+selectedClinic)}>{selectedClinic !== 'e' ? props.texts.req_dl : props.texts.dl}</button>}
      {selectedClinic !== 'e' && <> &nbsp; <button type="submit" className="pure-button pure-button-primary pure-input-rounded">{props.texts.req_next} &nbsp;&gt;</button></>}
    </p>
    </form>
  :
    <form id="reqform2" className="pure-form pure-form-aligned" onSubmit={(e) => {
      e.preventDefault();
      props.onSubmit({clinic: selectedClinic,
        lang: lang,
        datep: prelDate,
        pfn: patientFirstName,
        pln: patientLastName,
        psex: patientSex,
        pbdate: patientBirthDate,
        biopt: biopsyType,
        radio: radio,
        radiod: radioDetails,
        site: site,
        clini: rensClin,
        diag: diagnostics
      })}}>
      <div>
        <div className="form-aligned-row">
          <div className="form-aligned-left">
            {props.texts.clinic}:
          </div>
          <div className="form-aligned-right">
            {props.clinics.find(c => c.id === selectedClinic).name} &nbsp; <button className="pure-button pure-button-primary pure-input-rounded" onClick={() => setStep(0)} disabled={props.disabled}>{props.texts.edit}</button>
          </div>
        </div>
        <OptionField name="lang" caption={props.texts.req_lang} items={props.langs} value={lang} onChange={setLang} disabled={props.disabled} />
        <DateField name="datep" caption={props.texts.req_prel} placeholder={props.texts.req_dateform} value={prelDate} onChange={setPrelDate} lang={props.lang} disabled={props.disabled} />
        <SimpleField name="pfn" caption={props.texts.req_pfn} placeholder="" value={patientFirstName} onChange={setPatientFirstName} maxLength={30} disabled={props.disabled} />
        <SimpleField name="pln" caption={props.texts.req_pln} placeholder="" value={patientLastName} onChange={setPatientLastName} maxLength={40} disabled={props.disabled} />
        <OptionField name="psex" caption={props.texts.req_psex} items={{'M':props.texts.config_sm,'F':props.texts.config_sf}} value={patientSex} onChange={setPatientSex} disabled={props.disabled} />
        <DateField name="pbdate" caption={props.texts.req_pbdate} placeholder={props.texts.req_dateform} value={patientBirthDate} onChange={setPatientBirthDate} lang={props.lang} dropdown disabled={props.disabled} />
        <OptionField name="biopt" caption={props.texts.req_biopt} items={Object.assign({}, ...biop_types.map((t) => ({[t]: props.texts["req_biopt_"+t]})))} value={biopsyType} onChange={setBiopsyType} disabled={props.disabled} />
        <OptionField name="radio" caption={props.texts.req_radio} items={{"n": props.texts.req_radio_n, "o": props.texts.req_radio_o, "d": props.texts.req_radio_d}} value={radio} onChange={setRadio} optional disabled={props.disabled} />
        <LongField name="radiod" caption={props.texts.req_radiod} optional value={radioDetails} onChange={setRadioDetails} disabled={props.disabled} />
        <LongField name="req_site" caption={props.texts.req_site} value={site} onChange={setSite} disabled={props.disabled} />
        <LongField name="req_rens" caption={props.texts.req_rens} value={rensClin} onChange={setRensClin} optional disabled={props.disabled} />
        <LongField name="req_diag" caption={props.texts.req_diag} value={diagnostics} onChange={setDiagnostics} optional disabled={props.disabled} />
      </div>
    <h3>{props.texts.req_warn}</h3>
    <ol className="justify">
      {props.texts.req_warn_list.map(w => <li>{w}</li>)}
    </ol>
    <p className="confirm-box">
      <label><input type="checkbox" checked={isConfirmed} onChange={e => setIsConfirmed(e.target.checked)} disabled={props.disabled} />{props.texts.ord_conf}</label>
    </p>

    {isConfirmed && !isValid() && <p className="errorMessage">{props.texts.req_inc}</p>}

    <div>
      <button type="submit" className="pure-button pure-button-primary pure-input-rounded"
              disabled={!isConfirmed || !isValid() || props.disabled}
              >{props.texts.send}</button>
    </div>
  </form>);
}

function RequestFormGuest(props) {
  let biop_types = ['bi', 'be', 'ba', 'fr', 'ifd'];
  const [destFirstName, setDestFirstName] = useState("");
  const [destLastName, setDestLastName] = useState("");
  const [destPraNum, setDestPraNum] = useState("");
  const [destEmail, setDestEmail] = useState("");
  const [destClinicName, setDestClinicName] = useState("");
  const [destAddress, setDestAddess] = useState("");
  const [destCity, setDestCity] = useState("");
  const [destProvince, setDestProvince] = useState("QC");
  const [destPostalCode, setDestPostalCode] = useState("");
  const [destTelNum, setDestTelNum] = useState("");
  const [destFaxNum, setDestFaxNum] = useState("");
  const [prelDate, setPrelDate] = useState(new Date().toISOString().slice(0, 10));
  const [patientFirstName, setPatientFirstName] = useState("");
  const [patientLastName, setPatientLastName] = useState("");
  const [patientSex, setPatientSex] = useState("");
  const [patientBirthDate, setPatientBirthDate] = useState("");
  const [biopsyType, setBiopsyType] = useState("");
  const [radio, setRadio] = useState("");
  const [radioDetails, setRadioDetails] = useState("");
  const [site, setSite] = useState("");
  const [rensClin, setRensClin] = useState("");
  const [diagnostics, setDiagnostics] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const captchaRef = useRef();


  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  function isValid() {
    return destFirstName.trim() !== '' && destLastName.trim() !== '' && destPraNum.trim() !== '' && destEmail.trim() !== '' && validateEmail(destEmail) &&
           destClinicName.trim() !== '' && destAddress.trim() !== '' && destCity.trim() !== '' && destPostalCode.trim().length >= 6 && 
           destTelNum.trim() !== '' && prelDate.trim() !== '' &&  patientFirstName.trim() !== '' && patientLastName.trim() !== ''
           && patientSex !== '' && patientBirthDate.length === 10
           && biopsyType !== '' && site.trim().length > 1;
  }

  return (
    <form id="reqform" className="pure-form pure-form-aligned login-form" onSubmit={(e) => {
      e.preventDefault();
      const captcha = captchaRef.current.getValue();
      captchaRef.current.reset();

      props.onSubmit({lang: props.lang,
        d_fn: destFirstName,
        d_ln: destLastName,
        d_pranum: destPraNum,
        d_email: destEmail,
        d_clin: destClinicName,
        d_address: destAddress,
        d_city: destCity,
        d_prov: destProvince,
        d_pc: destPostalCode,
        d_tel: destTelNum,
        d_fax: destFaxNum,
        datep: prelDate,
        pfn: patientFirstName,
        pln: patientLastName,
        psex: patientSex,
        pbdate: patientBirthDate,
        biopt: biopsyType,
        radio: radio,
        radiod: radioDetails,
        site: site,
        clini: rensClin,
        diag: diagnostics,
        captcha: captcha
      })}}>
      <legend>{props.texts.req_new}</legend>
      <h2>{props.texts.req_dent}</h2>
      <div className="form-aligned">
        <SimpleField name="d_fn" caption={props.texts.config_fn} maxLength="40" value={destFirstName} onChange={setDestFirstName} disabled={props.disabled} />
        <SimpleField name="d_ln" caption={props.texts.config_ln} maxLength="40" value={destLastName} onChange={setDestLastName} disabled={props.disabled} />
        <SimpleField name="d_pranum" caption={props.texts.lic} placeholder="2-000000" maxLength="10" value={destPraNum} onChange={setDestPraNum} disabled={props.disabled} />
        <SimpleField name="d_email" caption={props.texts.email} placeholder={props.texts.config_emailph} maxLength="256" value={destEmail} onChange={setDestEmail} disabled={props.disabled} />
        <div className={destEmail !== '' && !validateEmail(destEmail) && document.activeElement !== document.getElementById('d_email') ? "form-aligned-row" : "hidden"}>
          <div className="form-aligned-left"></div><div className="form-aligned-right errorMessage">{props.texts.invalidemail}</div>
        </div>
        <SimpleField name="d_clin" caption={props.texts.config_clinname} placeholder="" maxLength="100" value={destClinicName} onChange={setDestClinicName} disabled={props.disabled} />
        <SimpleField name="d_address" caption={props.texts.config_address} maxLength="256" value={destAddress} onChange={setDestAddess} disabled={props.disabled} />
        <SimpleField name="d_city" caption={props.texts.config_city} maxLength="30" value={destCity} onChange={setDestCity} disabled={props.disabled} />
        <ProvincesSelect name="d_prov" caption={props.texts.config_prov} texts={props.texts.provinces} value={destProvince} onChange={setDestProvince} disabled={props.disabled} />
        <SimpleField name="d_pc" caption={props.texts.config_pc} maxLength="7" value={destPostalCode} onChange={setDestPostalCode} disabled={props.disabled} />
        <SimpleField name="d_tel" caption={props.texts.config_phone} placeholder="(xxx) xxx-xxxx" maxLength="20" value={destTelNum} onChange={setDestTelNum} disabled={props.disabled} />
        <SimpleField name="d_fax" caption={props.texts.config_fax} placeholder="(xxx) xxx-xxxx" maxLength="14" value={destFaxNum} onChange={setDestFaxNum} disabled={props.disabled} optional />
      </div>
      <h2>{props.texts.req}</h2>
      <div className="form-aligned">
        <DateField name="datep" caption={props.texts.req_prel} placeholder={props.texts.req_dateform} value={prelDate} onChange={setPrelDate} lang={props.lang} disabled={props.disabled} />
        <SimpleField name="pfn" caption={props.texts.req_pfn} placeholder="" value={patientFirstName} onChange={setPatientFirstName} maxLength={30} disabled={props.disabled} />
        <SimpleField name="pln" caption={props.texts.req_pln} placeholder="" value={patientLastName} onChange={setPatientLastName} maxLength={40} disabled={props.disabled} />
        <OptionField name="psex" caption={props.texts.req_psex} items={{'M':props.texts.config_sm,'F':props.texts.config_sf}} value={patientSex} onChange={setPatientSex} disabled={props.disabled} />
        <DateField name="pbdate" caption={props.texts.req_pbdate} placeholder={props.texts.req_dateform} value={patientBirthDate} onChange={setPatientBirthDate} lang={props.lang} dropdown disabled={props.disabled} />
        <OptionField name="biopt" caption={props.texts.req_biopt} items={Object.assign({}, ...biop_types.map((t) => ({[t]: props.texts["req_biopt_"+t]})))} value={biopsyType} onChange={setBiopsyType} disabled={props.disabled} />
        <OptionField name="radio" caption={props.texts.req_radio} items={{"n": props.texts.req_radio_n, "o": props.texts.req_radio_o, "d": props.texts.req_radio_d}} value={radio} onChange={setRadio} optional disabled={props.disabled} />
        <LongField name="radiod" caption={props.texts.req_radiod} optional value={radioDetails} onChange={setRadioDetails} disabled={props.disabled} />
        <LongField name="req_site" caption={props.texts.req_site} value={site} onChange={setSite} disabled={props.disabled} />
        <LongField name="req_rens" caption={props.texts.req_rens} value={rensClin} onChange={setRensClin} optional disabled={props.disabled} />
        <LongField name="req_diag" caption={props.texts.req_diag} value={diagnostics} onChange={setDiagnostics} optional disabled={props.disabled} />
    </div>
    <h3>{props.texts.req_warn}</h3>
    <ol className="justify">
      {props.texts.req_warn_list.map((w, i) => <li key={i}>{w}</li>)}
    </ol>
    <p className="confirm-box">
      <label><input type="checkbox" checked={isConfirmed} onChange={e => setIsConfirmed(e.target.checked)} disabled={props.disabled} />{props.texts.ord_conf}</label>
    </p>
    {isConfirmed && !isValid() && <p className="errorMessage">{props.texts.req_inc}</p>}

    <p><ReCAPTCHA sitekey="6LeqjyIjAAAAALwEUSZUWjcC2YpNoZv8n5hV7bc-" ref={captchaRef} style={{'display': 'inline-block'}}
                  onChange={v => setIsCaptchaValid(v !== null)} /></p>

    <div>
      <button type="submit" className="pure-button pure-button-primary pure-input-rounded"
              disabled={!isConfirmed || !isValid() || !isCaptchaValid || props.disabled}
              >{props.texts.send}</button>
    </div>
  </form>);
}

export {RequestForm, RequestFormGuest};