import React from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import menulogo from './logo-small.svg';
import homeicon from './home.svg';
import wheelicon from './wheel.svg';
import kiticon from './kit.svg';
import formicon from './form.svg';
import privacyicon from './privacy.svg';
import logouticon from './logout.svg';

class MenuConst {
  static rollback;
}

function toggleHorizontal() {
    let menu = document.getElementById('menu');
    menu.classList.remove('closing');
    [].forEach.call(
        document.getElementById('menu').querySelectorAll('.custom-can-transform'),
        function(el){
            el.classList.toggle('pure-menu-horizontal');
        }
    );
};

function toggleMenu() {
    let menu = document.getElementById('menu');
    // set timeout so that the panel has a chance to roll up
    // before the menu switches states
    if (menu.classList.contains('open')) {
        menu.classList.add('closing');
        MenuConst.rollBack = setTimeout(toggleHorizontal, 500);
    }
    else {
        if (menu.classList.contains('closing')) {
            clearTimeout(MenuConst.rollBack);
        } else {
            toggleHorizontal();
        }
    }
    menu.classList.toggle('open');
    document.getElementById('toggle').classList.toggle('x');
};

function closeMenu() {
    let menu = document.getElementById('menu');
    if (menu !== null && menu.classList.contains('open')) {
        toggleMenu();
    }
}

function handleClick(e) {
    let menu = document.getElementById('menu');
    if (menu !== null && menu.classList.contains('open')) {
        e.target.blur();
        toggleMenu();
    }
}

window.addEventListener(('onorientationchange' in window) ? 'orientationchange':'resize', closeMenu);

export default function Menu(props) {
  let history = useHistory();

  return (
    <div>
      <link rel="stylesheet" href="grids-responsive-min.css" />
      <div className="custom-wrapper pure-g" id="menu">
        <div className="pure-u-1 pure-u-xl-18-24 custom-float">
          <div className="custom-float">
            <div className="pure-menu">
              <div className="pure-menu-heading custom-brand"><img src={menulogo} className="pure-img" alt="Logo PathTeam" /></div>
              <button className="custom-toggle" id="toggle" onClick={(e) => {toggleMenu(); e.preventDefault();}}><s className="bar"></s><s className="bar"></s></button>
            </div>
          </div>
          <div className="custom-float left-menu">
            <div className="pure-menu pure-menu-horizontal custom-can-transform">
                <ul className="pure-menu-list">
                  <li className="pure-menu-item"><Link to="/" className="pure-menu-link link-color" onClick={handleClick}><img src={homeicon} className="menuicon"  alt="" />{props.titles.home}</Link></li>
                  <li className="pure-menu-item"><Link to="/config" className="pure-menu-link link-color" onClick={handleClick}><img src={wheelicon} className="menuicon" alt="" />{props.titles.config}</Link></li>
                  <li className="pure-menu-item"><Link to="/order" className="pure-menu-link link-color" onClick={handleClick}><img src={kiticon} className="menuicon" alt="" />{props.titles.order}&nbsp;&nbsp;</Link></li>
                  <li className="pure-menu-item"><Link to="/req" className="pure-menu-link link-color" onClick={handleClick}><img src={formicon} className="menuicon" alt="" />{props.titles.req}</Link></li>
                  <li className="pure-menu-item"><Link to="/privacy" className="pure-menu-link link-color" onClick={handleClick}><img src={privacyicon} className="menuicon" alt="" />{props.titles.priv}</Link></li>
                </ul>
            </div>
          </div>
        </div>
        <div className="pure-u-1 pure-u-xl-6-24">
          <div className="pure-menu pure-menu-horizontal custom-menu-3 custom-can-transform">
            <ul className="pure-menu-list">
              <li className="pure-menu-item"><button onClick={() => { history.push("/logout"); props.funcLoggedOut(); }} className="pure-menu-link link-color link-button"><img src={logouticon} className="menuicon" alt="" />{props.titles.logout}</button></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

