import React, { Component } from 'react';
import Login from './Login';
import Client from './Client';
import Privacy from './Privacy';
import WaitScreen from './WaitScreen';
import { BrowserRouter as Router, Route } from "react-router-dom";
import lang_fr from './lang-fr.json';
import lang_en from './lang-en.json';
import './PathTeam.css';

class PathTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isAuthenticated: false,
      isPrivacySigned: false,
      lang: '',
      langinfo: null
    }
  }
  
  langinfo = {'fr': lang_fr, 'en': lang_en};

  componentDidMount() {
    fetch("/patho/serviceClient.php", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'checkauth'
      }),
    }).then((Response) => Response.json()).then((resp) => {
      this.setState({
        isLoaded: true,
        isAuthenticated: (resp.auth === 'Y' || resp.auth === 'P'),
        isPrivacySigned: (resp.auth === 'Y'),
        lang: resp.lang
      });
    })
  }

  setLoggedIn(isLoggedIn, isPrivacySigned=false) {
    this.setState({
      isAuthenticated: isLoggedIn,
      isPrivacySigned: isPrivacySigned
    });
  }

  setLanguage(lang) {
    this.setState({
      lang: lang
    });
  }

  render() {
    return (
      this.state.isLoaded ?
        (this.state.isAuthenticated ?
          (this.state.isPrivacySigned ?
            <Client texts={this.langinfo[this.state.lang]} funcLoggedOut={() => this.setLoggedIn(false)} funcSetLang={(l) => this.setLanguage(l)} />
          : <Privacy texts={this.langinfo[this.state.lang]} lang={this.state.lang} onSigned={() => this.setState({isPrivacySigned: true})} funcLoggedOut={() => this.setLoggedIn(false)} viewMode={false} />)
        : <Router>
          <Route path="/" render={(p) => <Login langinfo={this.langinfo} lang={this.state.lang} funcSetLang={(l) => this.setLanguage(l)} funcLoggedIn={(lang, privacySigned) => { this.setLanguage(lang); this.setLoggedIn(true, privacySigned)}} />} />
        </Router>)
      : <WaitScreen lang={this.state.lang} />
    );
  }
}

export default PathTeam;

