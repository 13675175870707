import React from 'react';
import logo from './logo.svg';
import logo_en from './logo-en.svg';
import loading from './loading.svg';

export default function WaitScreen(props) {
  return (
    <div className="pure-u-sm-1 center">
      <div className="logo-header">
        <img src={props.lang === 'en' ? logo_en : logo}  className="pure-img" alt="Logo PathTeam" />
      </div>
      <div className="loading"><img src={loading} className="pure-img" alt="Chargement" /></div>
      {props.text !== undefined ? <div>{props.text}</div> : null}
    </div>
  );
}

