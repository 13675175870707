import React, { Component } from 'react';
import AjaxRequest from './AjaxRequest';
import './PathTeam.css';

class PasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passDifferent: false,
      passEmpty: true,
      passValid: false
    }
  }

  onChange() {
    if (this.state.passDifferent) {
      this.updateState();
    } else {
      let pass1 = document.getElementById('password').value;
      let pass2 = document.getElementById('password2').value;
      if (pass1 !== '' &&  pass1 === pass2) {
        this.updateState();
      }
    }
  }

  updateState() {
    let pass1 = document.getElementById('password').value;
    let pass2 = document.getElementById('password2').value;
    let isEmpty = (pass1 === '' ||  pass2 === '');
    let isDifferent = (!isEmpty && pass1 !== pass2);

    if (!isDifferent && !isEmpty) {
      AjaxRequest({action: 'passwordrules', password: pass1}, (resp) => {
        this.setState({
          passDifferent: false,
          passEmpty: false,
          passValid: resp.passValid
        }, () => {
          this.props.handleValidityChange(false, this.state.passValid);
        })});
    } else {
      this.setState({
        passDifferent: isDifferent,
        passEmpty: isEmpty,
        passValid: false
      }, () => {
        this.props.handleValidityChange(isEmpty, false);
      });
    }
  }

  render() {
    return(
      <div>   
        <div className="pure-u-1 pure-u-sm-2-3">
          {this.props.texts.passwd_rules}
        </div>
        {this.state.passDifferent ?
          <div className="pure-u-1 section-start errorMessage">
            {this.props.texts.passwd_diff}
          </div>
        : (!this.state.passEmpty && !this.state.passValid ?
          <div className="pure-u-1 section-start errorMessage">
            {this.props.texts.passwd_ruleserr}
          </div>
        : null)}
        <div className="pure-u-1 pure-u-sm-2-3 pure-u-md-13-24 form-aligned section-start">
          <div className="form-aligned-row">
            <div className="form-aligned-left">
              <label htmlFor="password">{this.props.texts.passwd}:</label>
            </div>
            <div className="form-aligned-right">
              <input id="password" type="password" maxLength="256" onBlur={() => this.updateState()} onChange={() => this.onChange()} disabled={this.props.disabled} />
            </div>
          </div>
          <div className="form-aligned-row">
            <div className="form-aligned-left">
              <label htmlFor="password2">{this.props.texts.passwd_conf}:</label>
            </div>
            <div className="form-aligned-right">
              <input id="password2" type="password" maxLength="256" onBlur={() => this.updateState()} onChange={() => this.onChange()} disabled={this.props.disabled} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordForm;

